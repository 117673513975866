import { Button } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import * as React from "react";
import styles from "./DividedButton.module.scss";
import arrow from "assets/successful_screen/circle_arrow.svg";

export interface IDividedButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onClick: () => void;
    isSubmit: boolean;
    idBase: string;
    disabled?: boolean;
}

export const DividedButton: React.FC<IDividedButtonProps> = (props: IDividedButtonProps) => {

    return <StylesProvider injectFirst>
        <Button type={props.isSubmit ? "submit" : "button"} onClick={props.onClick} classes={{ root: styles.root, label: styles.label }} disabled={props.disabled}>
            <div id={`${props.idBase}_contentContainer`} className={styles.contentContainer}>
                <div id={`${props.idBase}_childrenContainer`} className={styles.childrenContainer}>
                    {props.children}
                </div>
                {/* <div id={`${props.idBase}_iconContainer`} className={styles.iconContainer}>
                    <img id={`${props.idBase}_arrow`} src={arrow} alt="next_arrow" />
                </div> */}
            </div>
        </Button>
    </StylesProvider>
}