export enum Products {
    DriverSupport = "DRIVERSUPPORT",
    DsOne = "DRIVERSUPPORTONE",
    SolveIQ = "SOLVEIQ"
}

export interface ICheckExistingAccountData {
    IsExistingAccount: boolean,
    AllowPurchase: boolean;
    UserPortalUrl: string;
    CurrentCustomer: string;
}