import { useFetch } from "react-async";
import { v4 as uuidv4 } from "uuid";

export enum OptimizelyEventKeys {
    TEST_EVENT = "TEST_EVENT"
}

// this is not ideal. We need this mapping in order to use the optimizely event api
// directly, but this is a lot of very specific optimizely knowledge in the app itself.
const mapToEventId = (eventKey: OptimizelyEventKeys) => {
    switch (eventKey) {
        case OptimizelyEventKeys.TEST_EVENT: {
            return "18269263957"
        }
        default:
            return "18269263957";
    }
}

export const buildPost = (eventKey: OptimizelyEventKeys) => {

    return {
        "account_id": "17476411934",
        "visitors":
            [
                {
                    "visitor_id": "someuuid",
                    "attributes": [],
                    "snapshots": [
                        {
                            "decisions": [],
                            "events": [
                                {
                                    "entity_id": mapToEventId(eventKey),
                                    "key": eventKey,
                                    "timestamp": new Date().getTime(),
                                    "uuid": uuidv4()
                                }
                            ]
                        }
                    ]
                }
            ],
        "anonymize_ip": true,
        "client_name": "Asurvio/Prebuy_Onboarding",
        "client_version": "1.0.0",
        "enrich_decisions": true
    }

}

export class OptimizelyApi {

    public static async LogEvent(eventName: string) {

        // this is probably the easiest way to hit the optimizely event api
        // its pretty gross but at least is hidden away.
        // if we want more fine grained control we'll have to hit the 
        // event api directly via fetch (see commented out section).
        // we'll likely have to do some debugging to get that working right.
        //
        // using the event api directly also forces us to map the event name
        // to the event ID (see the maptoEventId method above) -- somehow the the optimizely window object knows 
        // how to do this mapping implicitly. The mapping forces a fairly
        // high degree of coupling between app and optimizely.
        window['optimizely' as any] = window['optimizely' as any] || [];
        (window['optimizely' as any] as any).push({
            type: "event",
            eventName: eventName,
            tags: {
            }
        });

        // private static url = "https://logx.optimizely.com/v1/events";
        // const response = await fetch(OptimizelyApi.url, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify(buildPost(OptimizelyEventKeys.TEST_EVENT))
        // });
    }

    //Manually trigger an optimizely page activation
    public static ActivateOptimizely(pageName:string) {
        let optimizely = (window['optimizely' as any] as any);
        optimizely = optimizely || [];
        optimizely.push({
        type: "page",
        pageName: pageName
        });
    }
}