import xIcon from "assets/xIcon_dark.png";
import * as React from "react";
import styles from "./ExistingAccountDetected_SolveIQ.module.scss";
import magnifier from "assets/magnify-illustration.png";

interface IExistingAccountDetectedSolveIQProps {
    onClose: () => void;
    onManageAccount: () => void;
    onPurchaseLicenses: () => void;
}

export const ExistingAccountDetected_SolveIQ: React.FC<IExistingAccountDetectedSolveIQProps> = (props: IExistingAccountDetectedSolveIQProps) => {
    return <div id="existingAccount" className={styles.existingAccount}>
        <button id="existingDsoneAccountCloseButton" className={styles.closeButton} onClick={props.onClose}>
            <img id="existingDsoneAccountCloseImage" className={styles.closeIcon} src={xIcon} alt="close" />
        </button>
        <div className={styles.existingAccount__contentContainer}>

            <img src={magnifier} alt="" />

            <h2 id="existingAccount__headerText" className={styles.existingAccount__headerText}>
                Existing Account Detected
            </h2>

            <h3 id="existingAccount__subHeaderText" className={styles.existingAccount__subHeaderText}>
                Based on your email we've detected that you already have an account with <span className={styles.productText}>Solve iQ</span>
            </h3>

            <button id="existingAccount__purchaseButton" className={styles.existingAccount__purchaseButton} onClick={props.onPurchaseLicenses}>purchase additional licenses</button>

            <button id="existingAccount__manageButton" className={styles.existingAccount__manageButton} onClick={props.onManageAccount}>manage existing account</button>

        </div>
    </div>
}
