import { ICartViewModel } from "models/ICartViewModel";

export enum ModuleViews {
    Welcome = "Welcome",
    Preregistration = "Preregistration",
    Cart = "Cart",
    ThankYou = "ThankYou",
}

export enum ModalViews {
    Login = "Login",
}

export interface IState {
    currentModuleView: ModuleViews;
    viewModel: ICartViewModel;
    isSubmittingCredentials: boolean;
    currentModalView: ModalViews | null;
    currentEmail: string;
    currentName: string;
}

const initialState: IState = {
    currentModuleView: ModuleViews.Welcome,
    viewModel: {} as ICartViewModel,
    isSubmittingCredentials: false,
    currentModalView: null,
    currentEmail: "",
    currentName: "",
}

export default initialState;