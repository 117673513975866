import * as React from "react";
import loader from "assets/loader.gif";
import styles from "./LoadingModal.module.scss";

export interface ILoadingModalProps {
    loadingMessage: JSX.Element;
    autoClose?: boolean;
    autoCloseTimeout?: number;
    onClose?: () => void;
}


export const LoadingModal: React.FC<ILoadingModalProps> = (props: ILoadingModalProps) => {

    const autoClose = props.autoClose;
    const onClose = props.onClose;
    const timeout = props.autoCloseTimeout;

    React.useEffect(() => {
        if (autoClose) {
            setTimeout(() => {
                if (onClose != null) {
                    onClose();
                }
            }, timeout)
        }
    }, [autoClose, onClose, timeout]);

    return <div id="loadingModal" className={styles.loadingModal}>
        <img className={styles.animatedLoader} id="loadingModalAnimatedLoader" src={loader} alt="spinner" />
        <div className={styles.loadingTextWrapper} id="loadingModalLoadingTextWrapper">
            {props.loadingMessage}
        </div>
    </div>;
}
