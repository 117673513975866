import magnifier from "assets/magnify-illustration.png";
import xIcon from "assets/xIcon_dark.png";
import * as React from "react";
import styles from "./ExistingAccountDetected_V10.module.scss";

interface IExistingAccountDetectedV10Props {
    onManageAccount: () => void;
    onClose: () => void;
}

export const ExistingAccountDetected_V10: React.FC<IExistingAccountDetectedV10Props> = (props: IExistingAccountDetectedV10Props) => {
    return <div id="existingAccount" className={styles.existingAccount}>
        <button id="existingv10AccountCloseButton" className={styles.closeButton} onClick={props.onClose}>
            <img id="existingv10AccountCloseImage" className={styles.closeIcon} src={xIcon} alt="close" />
        </button>
        <div className={styles.existingAccount__contentContainer}>
            <img src={magnifier} alt="" />

            <h2 id="existingAccount__headerText" className={styles.existingAccount__headerText}>
                Existing Account Detected
                        </h2>
            <h3 id="existingAccount__subHeaderText" className={styles.existingAccount__subHeaderText}>
                We've detected that you already have an account with Driver Support. Please manage <span className={styles.productText}>your existing account.</span>
            </h3>


            <button id="existingAccount__manageButton" className={styles.existingAccount__manageButton} onClick={props.onManageAccount}>manage existing account</button>
        </div>
    </div>
}
