import * as React from "react";
import NumberFormat from 'react-number-format';

interface INumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { value: string } }) => void;
    format: any;
}

export const NumberFormatInputAdapter = (props: INumberFormatCustomProps) => {
    const { inputRef, onChange, format, ...other } = props;

    return (
        <NumberFormat
            {...other}
            format={format}
            getInputRef={inputRef}
            onValueChange={(values: { value: any; }) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
        />
    );
}

