import * as React from "react";
import styles from "./Error.module.scss";

export interface IErrorProps {
    title: string;
    message?: string;
}


export const Error: React.FC<IErrorProps> = (props: IErrorProps) => {

    return (
        <div id="error_root" className={styles.root}>

            <div id="error_title_container" className={styles.title_container}>
                <h1 id="error_title" className={styles.title}>{props.title}</h1>
            </div>

            <div id="error_content_container" className={styles.content_container}>
                {props.message != null &&
                    <p id="error_messageText" className={styles.message}>{props.message}</p>
                }
            </div>
        </div>
    );
};

