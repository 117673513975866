import { createStyles, Dialog, makeStyles, Theme } from "@material-ui/core";
import * as React from "react";

export interface IDsOneDialogProps {
    open: boolean;
    children: any;
    disableBackdropClick: boolean;
}

export const DsOneDialog = (props: IDsOneDialogProps) => {

    // todo
    // should move this to *.module.scss for consistency
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            dialogPaper: {
                margin: '0',
                maxHeight: '100%',
                borderRadius: 14
            },
        },
        ));

    const classes = useStyles({});

    return <Dialog className={classes.dialogPaper} open={props.open} disableBackdropClick={props.disableBackdropClick} classes={{ paper: classes.dialogPaper }}>
        {props.children}
    </Dialog>
}