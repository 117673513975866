import React from 'react';
import styles from "./App.module.scss";
import { Root } from './components/Root';
import { AppProvider } from './store/context';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom'

declare var gtag: any;
declare var GA_TRACKING_ID: string;

Sentry.init({ dsn: "https://38c376b79ef44070a1358a96c7ca9b34@o94524.ingest.sentry.io/5436352" });
ReactGA.initialize("UA-2010741-26");

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <div className={styles.App}>
          <Root />
        </div>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
