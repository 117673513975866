import * as Sentry from '@sentry/react';
import { SendRequestExternalBrowser } from "api/clientFormApi";
import { OptimizelyApi } from "api/optimizelyApi";
import paypal from "assets/cart_screen/buy_paypal.png";
import shield_1 from "assets/cart_screen/shield-opt1.png";
import cards from "assets/credit_card/cc_icons_all.png";
import mapEndpoint, { Endpoints, getCommonQueryString, mapRoute, UiEndpoints } from "endpointFactory";
import { ICheckExistingAccountData, Products } from "models/ICheckExistingAccountData";
import { ICCPaymentWrapper } from "models/PaymentInfoPayload";
import { IDataGetResponse } from "models/Responses";
import moment from "moment";
import React from "react";
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { App, Msg } from "store/actions";
import { useAppDispatch, useAppState } from "store/context";
import { ExpiredPasswordError, WrappedError } from "utils/Errors";
import styles from "./Cart.module.scss";
import { DsOneDialog } from "./DsOneDialog";
import { CartForm } from "./forms/CartForm";
import { ExistingAccountDetected_DSOne as ExistingAccountDs1 } from "./modals/ExistingAccountDetected_DSOne";
import { ExistingAccountDetected_SolveIQ } from './modals/ExistingAccountDetected_SolveIQ';
import { ExistingAccountDetected_V10 as ExistingAccountV10 } from "./modals/ExistingAccountDetected_V10";
import { GeneralErrorModal } from "./modals/GeneralErrorModal";
import { LoadingModal } from "./modals/LoadingModal";

interface IPurchaseData {
    name: string,
    email: string,
    cardNumber: string,
    cardExpirationMonth: string,
    cardExpirationYear: string,
    cardCvc: string,
    phoneNumber: string,
    zipCode: string,
}


export interface ICartScreenProps {
}

const submitCCPaymentFunc = async (
    name: string,
    email: string,
    cardNumber: string,
    cardExpirationMonth: string,
    cardExpirationYear: string,
    cardCvc: string,
    phoneNumber: string,
    zipCode: string,
    querystringdata: string) => {
    try {
        const response = await fetch(mapEndpoint(Endpoints.CreditCardPurchase)!, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode, querystringdata })
        })

        if (response.ok) {
            const responseObject = await response.json() as IDataGetResponse;
            if (responseObject.success) {
                const paymentInfo = JSON.parse(responseObject.payload) as ICCPaymentWrapper;
                if (paymentInfo == null) {
                    throw new Error("Unable to parse response from submit credit card payment request");
                } else {
                    return paymentInfo;
                }
            } else {
                throw new Error("Submit credit card payment responded with an unsuccessful status: " + responseObject.message)
            }
        } else {
            throw new Error("Server responded with: " + response.statusText)
        }
    } catch (ex) {
        //Sentry.captureException(new WrappedError("An error occurred while submitting a credit card payment", ex))
        return Promise.reject(ex);
    }
}

const submitPayPalPaymentFunc = async (name: string, email: string, queryStringData: string) => {
    try {
        const response = await fetch(mapEndpoint(Endpoints.PayPalPurchase)!, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, name, queryStringData })
        })

        if (response.ok) {
            const responseObject = await response.json() as IDataGetResponse;
            if (responseObject.success) {
                const paypalUrl = responseObject.payload as string;
                if (paypalUrl == null) {
                    throw new Error("Unable to parse response from paypal purchase request")
                } else {
                    return paypalUrl;
                }
            } else {
                throw new Error("Submit paypal payment responded with an unsuccessful status: " + responseObject.message)
            }
        } else {
            throw new Error("Server responded with: " + response.statusText)
        }
    } catch (ex) {
        //Sentry.captureException(new WrappedError("An error occurred while submitting a credit card payment", ex))
        return Promise.reject(ex);
    }
}

const checkAccountFunc = async (email: string) => {
    try {
        const response = await fetch(mapEndpoint(Endpoints.CheckExistingAccount)!, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(email)
        })

        if (response.ok) {
            const responseObject = await response.json() as IDataGetResponse;
            if (responseObject.success) {
                const accountInfo = JSON.parse(responseObject.payload) as ICheckExistingAccountData;
                if (accountInfo == null) {
                    throw new Error("Unable to parse response from check account request");
                } else {
                    return accountInfo;
                }
            } else {
                throw new Error("Check account responded with an unsuccessful status: " + responseObject.message)
            }
        } else {
            throw new Error("Server responded with: " + response.statusText)
        }
    } catch (ex) {
        //Sentry.captureException(new WrappedError("An error occurred while submitting an account license check", ex))
        return Promise.reject(ex);
    }
}

export const Cart: React.FC<ICartScreenProps> = (props: ICartScreenProps) => {

    const state = useAppState();
    const dispatch = useAppDispatch();
    const history = useHistory();
    ;

    const [email, setEmail] = React.useState(state.currentEmail);
    const [paymentError, setPaymentError] = React.useState("");
    const [isExistingDsoneAccount, setIsExistingDsoneAccount] = React.useState(false);
    const [isExistingV10Account, setIsExistingV10Account] = React.useState(false);
    const [isExistingSolveIQAccount, setIsExistingSolveIQAccount] = React.useState(false);
    const [portalUrl, setPortalUrl] = React.useState("");
    const [isCCPaymentPending, setIsCCPaymentPending] = React.useState(false);
    const [hasCheckedAccount, setHasCheckedAccount] = React.useState(state.currentEmail != null && state.currentEmail !== "");
    const [isPayPalPaymentPending, setIsPayPalPaymentPending] = React.useState(false);
    const [currentAccountData, setCurrentAccountData] = React.useState({} as IPurchaseData);

    const checkAccount = React.useCallback(async (email: string) => {
        return checkAccountFunc(email);
    }, []);


    const ccPrecheck = async (name: string,
        email: string,
        cardNumber: string,
        cardExpirationMonth: string,
        cardExpirationYear: string,
        cardCvc: string,
        phoneNumber: string,
        zipCode: string) => {

        try {
            if (!hasCheckedAccount) {
                const checkData = await checkAccount(email);
                setHasCheckedAccount(true);
                if (checkData.IsExistingAccount && checkData.CurrentCustomer.toLowerCase() === Products.DsOne.toString().toLowerCase()) {
                    ReactGA.event({ category: "Account", action: "existing dsone account detected" });
                    setCurrentAccountData({ name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode } as IPurchaseData)
                    transition_to_ExistingAccountDsOne(checkData.UserPortalUrl);
                    return;
                } else if (checkData.IsExistingAccount && checkData.CurrentCustomer.toLowerCase() === Products.DriverSupport.toString().toLowerCase()) {
                    ReactGA.event({ category: "Account", action: "existing v10 account detected" });
                    setCurrentAccountData({ name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode } as IPurchaseData)
                    transition_to_ExistingAccountV10(checkData.UserPortalUrl);
                    return;
                } else if (checkData.IsExistingAccount && checkData.CurrentCustomer.toLowerCase() === Products.SolveIQ.toString().toLowerCase()) {
                    ReactGA.event({ category: "Account", action: "existing solveiq account detected" });
                    setCurrentAccountData({ name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode } as IPurchaseData)
                    transition_to_ExistingAccountSolveIQ(checkData.UserPortalUrl);
                    return;
                }
            }

            handleCCSubmit(name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode);

        } catch (ex) {
            Sentry.captureException(new WrappedError("An error occurred while submitting an account license check", ex))
        }
        finally {
            // if something goes wrong and we can't check if there's an existing account then just default to
            // letting the transaction go through
            //setHasCheckedAccount(true);
        }
    }

    const submitPayment = React.useCallback(async (
        name: string,
        email: string,
        cardNumber: string,
        cardExpirationMonth: string,
        cardExpirationYear: string,
        cardCvc: string,
        phoneNumber: string,
        zipCode: string,
        querystringdata: string) => {
        return submitCCPaymentFunc(name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode, querystringdata)
    }, [])

    const handleCCSubmit = async (name: string,
        email: string,
        cardNumber: string,
        cardExpirationMonth: string,
        cardExpirationYear: string,
        cardCvc: string,
        phoneNumber: string,
        zipCode: string) => {
        OptimizelyApi.LogEvent("CC_SUBMIT_PAYMENT_INFO");
        ReactGA.event({ category: "Cart", action: "credit card submit button clicked" });

        // trigger the pending purchase modal
        transition_to_CCPending();

        try {
            ReactGA.event({ category: "Cart", action: "existing v10 account detected" });
            OptimizelyApi.LogEvent("CC_SUBMIT_PAYMENT_INFO");
            const paymentResponse = await submitPayment(name, email, cardNumber, cardExpirationMonth, cardExpirationYear, cardCvc, phoneNumber, zipCode, getCommonQueryString())
            if (paymentResponse.RedirectUrl != null) {
                window.location.href = paymentResponse.RedirectUrl;
            }

        } catch (error) {
            OptimizelyApi.LogEvent("CC_PURCHASE_FAILURE");
            ReactGA.event({ category: "Cart", action: "credit card purchase failure" });
            transition_to_PaymentError("An error occurred");
        }
    }

    const handlePayPalSubmit = async (name: string, email: string) => {
        OptimizelyApi.LogEvent("PAYPAL_SUBMIT_PAYMENT_INFO");
        ReactGA.event({ category: "Cart", action: "paypal button clicked" });

        // trigger the paypal pending purchase modal
        transition_to_PayPalPending();

        try {
            const paypalUrl = await submitPayPalPaymentFunc(name, email, getCommonQueryString());
            ReactGA.event({ category: "Cart", action: "redirecting to paypal site" });
            window.location.href = paypalUrl;

        } catch (ex) {
            OptimizelyApi.LogEvent("PAYPAL_PURCHASE_FAILURE");
            ReactGA.event({ category: "Cart", action: "pay pal purchase failure" });
            transition_to_PaymentError("An error occurred");
        }
    }

    // we now do an email check everytime someone hits the complete purchase button
    // const handleEmailBlur = async (email: string) => {

    //     setEmail(email);

    //     if (email == null || email == "" || isCCPaymentPending || isPayPalPaymentPending) {
    //         return;
    //     }

    //     setHasCheckedAccount(false);

    //     try {
    //         const accountInfo = await checkAccount(email);
    //         if (accountInfo.IsExistingAccount && accountInfo.AllowPurchase) {
    //             ReactGA.event({ category: "Account", action: "existing dsone account detected" });
    //             transition_to_ExistingAccountDsOne();
    //             return;
    //         } else if (accountInfo.IsExistingAccount && !accountInfo.AllowPurchase) {
    //             ReactGA.event({ category: "Account", action: "existing v10 account detected" });
    //             transition_to_ExistingAccountV10();
    //             return;
    //         }
    //     } catch (ex) {
    //         Sentry.captureException(new WrappedError("An error occurred while submitting an account license check", ex))
    //     }
    //     finally {
    //         // if something goes wrong and we can't check if there's an existing account then just default to
    //         // letting the transaction go through
    //         setHasCheckedAccount(true);
    //     }

    // }

    // these are state transition thunks
    // we should probably move to a reducer instead of trying to manage state directly
    // via useState
    const transition_to_ExistingAccountV10 = (portalUrl: string) => {
        setIsExistingV10Account(true);
        setIsExistingDsoneAccount(false);
        setIsExistingSolveIQAccount(false);
        setPaymentError("");
        setIsCCPaymentPending(false);
        setIsPayPalPaymentPending(false);
        setPortalUrl(portalUrl);
    }

    const transition_to_ExistingAccountDsOne = (portalUrl: string) => {
        setIsExistingV10Account(false);
        setIsExistingDsoneAccount(true);
        setIsExistingSolveIQAccount(false);
        setPaymentError("");
        setIsCCPaymentPending(false);
        setIsPayPalPaymentPending(false);
        setPortalUrl(portalUrl);
    }

    const transition_to_ExistingAccountSolveIQ = (portalUrl: string) => {
        setIsExistingV10Account(false);
        setIsExistingDsoneAccount(false);
        setIsExistingSolveIQAccount(true);
        setPaymentError("");
        setIsCCPaymentPending(false);
        setIsPayPalPaymentPending(false);
        setPortalUrl(portalUrl);
    }

    const transition_to_PaymentError = (paymentError: string) => {
        setIsExistingV10Account(false);
        setIsExistingDsoneAccount(false);
        setIsExistingSolveIQAccount(false);
        setPaymentError(paymentError);
        setIsCCPaymentPending(false);
        setIsPayPalPaymentPending(false);
    }

    const transition_to_CCPending = () => {
        setIsExistingV10Account(false);
        setIsExistingDsoneAccount(false);
        setIsExistingSolveIQAccount(false);
        setPaymentError("");
        setIsCCPaymentPending(true);
        setIsPayPalPaymentPending(false);
    }

    const transition_to_PayPalPending = () => {
        setIsExistingV10Account(false);
        setIsExistingDsoneAccount(false);
        setIsExistingSolveIQAccount(false);
        setPaymentError("");
        setIsCCPaymentPending(false);
        setIsPayPalPaymentPending(true);
    }

    const transition_to_Clear = () => {
        setIsExistingV10Account(false);
        setIsExistingDsoneAccount(false);
        setIsExistingSolveIQAccount(false);
        setPaymentError("");
        setIsCCPaymentPending(false);
        setIsPayPalPaymentPending(false);
    }

    const getCartForm = () => {
        return <div id="cart_form_container" className={styles.cart_container}>
            <p id="cart_total_text" className={styles.cart_total_text}>Cart Total: <span id="cart_total_value" className={styles.cart_total_value}>{`$${monthlyPrice}`}</span></p>
            <CartForm initialEmail={state.currentEmail != null ? state.currentEmail : ""}
                onSubmit={ccPrecheck}
                initialName={state.currentName != null ? state.currentName : ""}
                currentDate={moment(state.viewModel.currentDateTime)} />
        </div>
    }

    const getPayPalForm = () => {
        return <div id="cart_paypal_container" className={styles.paypal_container}>
            <img id="paypal_shield" className={styles.paypal_shield} src={shield_1} />
            <p id="paypal_desc" className={styles.paypal_desc}>
                After clicking "Continue to PayPal" below, you will be redirected to PayPal to complete your purchase securely.
                </p>
            <button id="paypal_button" className={styles.paypal_button}
                onClick={() => handlePayPalSubmit(state.currentName, state.currentEmail)}>Continue To Paypal</button>
        </div>
    }

    const monthlyPrice = "9.99";
    const machineName = state.viewModel.machineName == null ? "laptop or PC" : state.viewModel.machineName;

    return (
        <div id="cart_container" className={styles.container}>

            <DsOneDialog disableBackdropClick={true} open={isCCPaymentPending}>
                <LoadingModal autoClose={true} autoCloseTimeout={60000}
                    onClose={() => setIsCCPaymentPending(false)}
                    loadingMessage={
                        <div id="cartCompact__loadingContainer" className={styles.loadingContainer}>
                            <p>Completing your purchase.</p>
                            <p>(this may take a moment)</p>
                        </div>} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isPayPalPaymentPending}>
                <LoadingModal autoClose={false} autoCloseTimeout={10000} loadingMessage={<p>Redirecting to PayPal...</p>} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={paymentError != null && paymentError !== ""}>
                <GeneralErrorModal errorMessage="Please check your payment info and try again"
                    onClose={() => {
                        setPaymentError("")
                    }}
                />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isExistingDsoneAccount}>
                <ExistingAccountDs1
                    onClose={() => {
                        // reset hasCheckedAccount so we don't let a non-solveiq user through
                        setHasCheckedAccount(false);
                        transition_to_Clear();
                    }}
                    onManageAccount={() => {
                        window.open(portalUrl, "_blank", "noopener,noreferrer");
                    }} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isExistingV10Account}>
                <ExistingAccountV10
                    onClose={() => {
                        // reset hasCheckedAccount so we don't let a non-solveiq user through
                        setHasCheckedAccount(false);
                        transition_to_Clear();
                    }}
                    onManageAccount={() => {
                        window.open(portalUrl, "_blank", "noopener,noreferrer");
                    }} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isExistingSolveIQAccount}>
                <ExistingAccountDetected_SolveIQ
                    onClose={() => {
                        transition_to_Clear();
                    }}
                    onManageAccount={() => {
                        window.open(portalUrl, "_blank", "noopener,noreferrer");
                    }}
                    onPurchaseLicenses={() => {
                        // no need for pre-check since we'd have to have run it already to get here
                        // so just slam in the credit card submit
                        handleCCSubmit(currentAccountData.name,
                            currentAccountData.email,
                            currentAccountData.cardNumber,
                            currentAccountData.cardExpirationMonth,
                            currentAccountData.cardExpirationYear,
                            currentAccountData.cardCvc,
                            currentAccountData.phoneNumber,
                            currentAccountData.zipCode);
                    }} />
            </DsOneDialog>

            <header id="cart_header" className={styles.header}>

                <div id="header_left_side" className={styles.header_left}>
                    <h3 id="left_side_title" className={styles.header_left_title}>Solve iQ</h3>
                    <p id="left_side_desc" className={styles.header_left_desc}>{`Easy to use driver update software.  
                    Operating System(s): (32/64 Bit) Windows 10 / Windows 8 / Windows 7.
                    This is a subscription product with a fee of ${monthlyPrice} per month. You may cancel your subscription at any time. `}</p>
                </div>

                <div id="header_right_side" className={styles.header_right}>
                    {/* <button id="cart_back_button" className={styles.back_button}
                        onClick={() => dispatch(Msg(App.CartBack, { navFunc: history.push(mapRoute(UiEndpoints.Preregistration)) }))}>back</button> */}
                    <h3 id="right_side_title" className={styles.header_right_content}>{`Price ${monthlyPrice}/mo`}</h3>
                </div>

            </header>

            <header id="cart_title_header">
                <h1 id="cart_title" className={styles.title}>Update out-of-date or missing drivers for your {machineName}</h1>
                <h3 id="cart_subTitle" className={styles.subTitle}>Keep your devices updated with the latest drivers using our driver update sevice</h3>
            </header>

            <main id="cart_body" className={styles.body}>
                <Tabs  >
                    <TabList className={styles.tab}>
                        <Tab className={styles.tabItemRoot} selectedClassName={styles.tabItemSelected}>
                            <div className={styles.cc_tab_container}>
                                <p id="cart_credit_debit" className={styles.cc_tab_text}>Credit/Debit Card</p>
                                <img id="cart_cc_icon" className={styles.cc_tab_icon} src={cards} />
                            </div>
                        </Tab>
                        <Tab className={styles.tabItemRoot} selectedClassName={styles.tabItemSelected}>
                            <img id="cart_paypal_icon" src={paypal} />
                        </Tab>
                    </TabList>
                    <TabPanel>
                        {getCartForm()}
                    </TabPanel>
                    <TabPanel>
                        {getPayPalForm()}
                    </TabPanel>
                </Tabs>
            </main>
            <div id="cart_spacer" className={styles.spacer} />
            <footer id="cart_footer" className={styles.footer}>
                <p className={styles.policy} id="cart_footer_text">
                    Sales tax charge will be applied, based on resident state requirements.
                    By submitting this order you agree to the
                    <span id="cart_footer_terms">
                        <button id="prereg_terms_link" className={styles.termsButton}
                            onClick={() => {
                                ReactGA.event({ category: "Account", action: "redirecting to terms and conditions page" });
                                window.open(state.viewModel.termsAndConditionsUrl, "_blank", "noopener,noreferrer");
                            }}>
                            Terms and Conditions
                            </button>
                    </span>
                            and
                    <span id="cart_footer_privacy_policy">
                        <button id="prereg_privacy_link" className={styles.privacypolicyButton}
                            onClick={() => {
                                ReactGA.event({ category: "Account", action: "redirecting to privacy policy page" });
                                window.open(state.viewModel.privacyPolicyUrl, "_blank", "noopener,noreferrer");
                            }}>
                            Privacy Policy
                            </button>
                    </span>
                    of Solve iQ and confirm that you have read and understood as a customer.
                    You also confirm that you are signing up for a subscription product that
                    will be billed in regular intervals at a rate of $9.99 /month. Continued use
                    of the purchased product is contingent upon your payment of the subscription.
                    Details about the price and frequency of the billing were shown in the shopping cart above.
            </p>
            </footer>
        </div>

    );
};