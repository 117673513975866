import { InputAdornment, TextField } from "@material-ui/core";
import amex from "assets/credit_card/small_Amex.png";
import discover from "assets/credit_card/small_Discover.png";
import mastercard from "assets/credit_card/small_MasterCard.png";
import visa from "assets/credit_card/small_Visa.png";
import * as React from "react";
import small_creditCard from "assets/credit_card/small_creditCard.png";
import { CleaveCreditCardInputAdapter } from "./CleaveCreditCardInputAdapter";
import { CreditCardTypes } from "./CreditCardTypes";

// typing these would be a total pain, so I'm going to be lazy.
// this is the interface expected from react-final-form Field
interface MyCCInputAdapterProps {
    input: any;
    meta: any;
    InputProps: any;
    onCreditCardTypeChanged?: (cardType: CreditCardTypes) => void
}

interface MyCCInputAdapterState {
    ccIcon: string;
}

// Adapter for using Material UI component with React Final Form.
// Typically input adapters are thing stateless wrappers around the component that needs to be adapted. However, this
// adapter uses state to keep consumers from encountering issues with Cleave's incompatibility with React Hooks.
// By isolating the state code here consumers are free to use React Hooks for typical use cases like styling.
export class MUICreditCardInputAdapter extends React.Component<MyCCInputAdapterProps, MyCCInputAdapterState>{

    constructor(props: MyCCInputAdapterProps) {

        super(props);

        this.state = {
            ccIcon: small_creditCard
        };

        this.onCreditCardTypeChanged = this.onCreditCardTypeChanged.bind(this);
    }

    public render() {

        const { input, meta, ...rest } = this.props;
        const { name, onChange, value, ...restInput } = input;

        return (
            <TextField
                {...rest}
                value={value}
                onChange={onChange}
                helperText={meta.touched && meta.error ? meta.error : " "}
                error={meta.touched && meta.error ? true : false}
                inputProps={{
                    ...restInput,
                    value,
                    onCreditCardTypeChanged: this.onCreditCardTypeChanged
                }}
                InputProps={{
                    ...rest.InputProps,
                    startAdornment: <InputAdornment position="start"><img src={this.state.ccIcon} alt="credit_card" /></InputAdornment>,
                    inputComponent: CleaveCreditCardInputAdapter
                }}
            />
        );
    }

    private onCreditCardTypeChanged(type: string) {

        let icon = small_creditCard;

        if (type === "visa") {
            icon = visa;
        } else if (type === "amex") {
            icon = amex;
        } else if (type === "discover") {
            icon = discover;
        } else if (type === "mastercard") {
            icon = mastercard;
        }

        this.setState({
            ccIcon: icon
        });

        if (this.props.onCreditCardTypeChanged) {
            this.props.onCreditCardTypeChanged(this.mapToCCType(type));
        }

    }

    private mapToCCType(ccType: string): CreditCardTypes {

        const lower = ccType.trim().toLowerCase();
        if (lower === "visa") {
            return CreditCardTypes.visa;
        } else if (lower === "amex") {
            return CreditCardTypes.amex;
        } else if (lower === "discover") {
            return CreditCardTypes.discover;
        } else if (lower === "mastercard") {
            return CreditCardTypes.mastercard;
        }
        return CreditCardTypes.unknown;
    }
}