import { ActionMap, createMsg } from "../utils/types";
import { ICartViewModel } from "models/ICartViewModel";

// unique identifiers for messages in this module
export enum App {
    WelcomeComplete = "App/WelcomeComplete",
    Close = "App/CloseWindow",
    Minimize = "App/MinimizeWindow",
    StartDrag = "App/StartDrag",
    DataFetchComplete = "App/DataFetchComplete",
    PreRegComplete = "App/PreRegComplete",
    //SkipAccountCreation = "App/SkipAccountCreation",
    PreRegBack = "App/PreRegBack",
    CartBack = "App/CartBack",
    //CCPurchaseComplete = "App/CCPurchaseComplete",
    //PayPalPurchaseComplete = "App/PayPalPurchaseComplete"
}

// 'shape' of messages in this module
type Messages = {
    [App.WelcomeComplete]: { navFunc: any };
    [App.Close]: undefined;
    [App.Minimize]: undefined;
    [App.StartDrag]: undefined;
    [App.DataFetchComplete]: { navFunc: any, vm: ICartViewModel };
    [App.PreRegComplete]: { navFunc: any, name: string, email: string };
    // [App.SkipAccountCreation]: { name: string };
    [App.PreRegBack]: { navFunc: any };
    [App.CartBack]: { navFunc: any };
    //[App.CCPurchaseComplete]: { navFunc: any };
    //[App.PayPalPurchaseComplete]: { navFunc: any };
};

// utility that combines ids and messages into a single action that can be dispatched
export const Msg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];

export default Messages;

