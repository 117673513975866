import * as React from "react";
import { TextField } from "@material-ui/core";
import { NumberFormatInputAdapter } from "./NumberFormatInputAdapter";

// Adapter for using Material UI component with React Final Form.
export const MUIDateInputAdapter = ({ input, meta, InputProps, ...rest }: { input: any, meta: any, InputProps: any }) => (
    <TextField
        {...input}
        {...rest}
        onChange={(event) => input.onChange(event.target.value)}
        helperText={meta.touched && meta.error ? meta.error : " "}
        error={meta.touched && meta.error ? true : false}

        inputProps={{ format: cardExpiry }}
        InputProps={{
            ...InputProps,
            inputComponent: NumberFormatInputAdapter,
        }}
    />

)

// a lot of this needs to be moved to a utility class
function limit(val: string | any[], max: string | number[]) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';

            //this can happen when user paste number
        } else if (val > max) {
            val = max;
        }
    }

    return val;
}

function cardExpiry(val: string) {
    let month = limit(val.substring(0, 2), '12');
    let year = val.substring(2, 4);
    return month + (year.length ? '/' + year : '');
}


