import * as React from "react";
import icon from "assets/handWithMagnifier.png";
import styles from "./GeneralErrorModal.module.scss";
import { Button } from "@material-ui/core";

export interface IGeneralErrorModalProps {
    errorMessage: string;
    onClose: () => void;
}

export const GeneralErrorModal: React.FC<IGeneralErrorModalProps> = (props: IGeneralErrorModalProps) => {
    return <div id="generalErrorModal" className={styles.errorModal}>
        <main id="error_main_wrapper" className={styles.main}>
            <img className={styles.errorImage} id="errorModalImage" src={icon} alt="" />
            <div className={styles.errorTextWrapper} id="errorModalTextWrapper">
                <h1 id="error_title" className={styles.title}>Something went wrong!</h1>
                <p id="error_content" className={styles.content}>
                    {props.errorMessage}
                </p>
            </div>
        </main>
        <footer id="error_footer" className={styles.footer}>
            <Button id="error_close_button" className={styles.closeButton} onClick={props.onClose}>close</Button>
        </footer>
    </div>;
}