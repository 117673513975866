import { CreditCardTypeChangeHandler } from "cleave.js/options/creditCard";
import Cleave from "cleave.js/react";
import * as React from "react";

interface ICleaveCreditCardInputAdapterProps {
    inputRef: any,
    onCreditCardTypeChanged: CreditCardTypeChangeHandler
}

export const CleaveCreditCardInputAdapter = (props: ICleaveCreditCardInputAdapterProps) => {
    const { inputRef, onCreditCardTypeChanged, ...other } = props;
    return (
        <Cleave
            {...other}
            htmlRef={inputRef}
            options={{
                creditCard: true,
                creditCardStrictMode: false,
                onCreditCardTypeChanged: onCreditCardTypeChanged
            }}
        />
    );
}