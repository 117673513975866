import { SendCloseClicked, SendMinimizeClicked, SendTitleBarMouseDown } from 'api/clientFormApi';
import { Actions, App } from './actions';
import { IState, ModalViews, ModuleViews } from './initialState';
import ReactGA from 'react-ga';
import { OptimizelyApi } from 'api/optimizelyApi';

declare var optimizelyPageName: string;

const reducer = (state: IState, action: Actions): IState => {

    switch (action.type) {
        case App.WelcomeComplete: {
            ReactGA.pageview("PreRegistration");
            return {
                ...state,
                currentModuleView: ModuleViews.Preregistration,
            };
        }
        case App.Close: {
            ReactGA.event({ category: "App", action: "app closed" });
            SendCloseClicked();
            return { ...state };
        }
        case App.Minimize: {
            ReactGA.event({ category: "App", action: "app minimized" });
            SendMinimizeClicked();
            return { ...state };
        }
        case App.StartDrag: {
            ReactGA.event({ category: "App", action: "app dragged" });
            SendTitleBarMouseDown();
            return { ...state };
        }
        case App.DataFetchComplete: {
            ReactGA.pageview("Welcome");
            //set install age object on window for targetting
            (window as { [key: string]: any })['InstallAge'] = action.payload.vm.installAge;
            //activate any running optimizely experiments
            OptimizelyApi.ActivateOptimizely(optimizelyPageName);
            return {
                ...state,
                currentModuleView: ModuleViews.Welcome,
                viewModel: action.payload.vm,
                currentEmail: action.payload.vm.email,
                currentName: action.payload.vm.name
            };
        }
        // case App.SkipAccountCreation: {
        //     ReactGA.event({ category: "Account", action: "No email provided, skipping account creation" });
        //     ReactGA.pageview("Cart");
        //     return {
        //         ...state,
        //         currentName: action.payload.name,
        //         currentModuleView: ModuleViews.Cart
        //     }
        // }
        case App.PreRegComplete: {
            ReactGA.event({ category: "Account", action: "pre registration complete" });
            ReactGA.pageview("Cart");
            OptimizelyApi.LogEvent("CREATE_ACCOUNT_COMPLETE");
            return {
                ...state,
                currentEmail: action.payload.email,
                currentName: action.payload.name,
                currentModuleView: ModuleViews.Cart
            }
        }
        // case App.CCPurchaseComplete: {
        //     ReactGA.event({ category: "Account", action: "cc purchase complete" });
        //     ReactGA.pageview("ThankYou");
        //     OptimizelyApi.LogEvent("CC_PURCHASE_SUCCESSFUL");
        //     return {
        //         ...state,
        //         currentModuleView: ModuleViews.ThankYou
        //     }
        // }
        // case App.PayPalPurchaseComplete: {
        //     ReactGA.event({ category: "Account", action: "paypal purchase complete" });
        //     ReactGA.pageview("ThankYou");
        //     OptimizelyApi.LogEvent("PAYPAL_PURCHASE_SUCCESSFUL");
        //     return {
        //         ...state,
        //         currentModuleView: ModuleViews.ThankYou
        //     }
        // }
        case App.PreRegBack: {
            ReactGA.pageview("Welcome");
            return {
                ...state,
                currentModuleView: ModuleViews.Welcome
            }
        }
        case App.CartBack: {
            ReactGA.pageview("PreRegistration");
            return {
                ...state,
                currentModuleView: ModuleViews.Preregistration
            }
        }
        // leave this commented out. With no default defined intellisense will tell you when
        // you are missing an action handler.
        // default:
        //     return {...state};
    }
}

export default reducer;