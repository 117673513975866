import background from "assets/Background.png"
import * as React from "react";
import styles from "./Loading.module.scss";

export interface ILoadingProps {
    title: string;
    subtitle?: string;
    description?: string;
    loadingMessage?: string;
}


export const Loading: React.FC<ILoadingProps> = (props: ILoadingProps) => {

    return (
        <div id="loading_root" className={styles.root}>

            <div id="loading_title_container" className={styles.title_container}>
                <h1 id="loading_title" className={styles.title}>{props.title}</h1>
            </div>

            <div id="loading_content_container" className={styles.content_container}>

                {props.subtitle &&
                    <h2 id="loading_subtitle" className={styles.subtitle}>{props.subtitle}</h2>
                }

                {props.description != null &&
                    <p id="loading_description" className={styles.description}>{props.description}</p>
                }

                <div id="loading_spinner" className={styles.spinner} />

                {props.loadingMessage != null &&
                    <p id="loading_loadText" className={styles.loading_message}>{props.loadingMessage}</p>
                }
            </div>
        </div>
    );
};

