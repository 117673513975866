import { InputAdornment, withStyles } from "@material-ui/core";
import * as React from "react";
import { Field, Form } from "react-final-form";
import small_email from "assets/preregistration_screen/email_icon.svg";
import small_user from "assets/preregistration_screen/person_icon.svg";
import styles from "./CreateAccountForm.module.scss";
import { Validation } from "utils/Validation";
import { MUITextInputAdapter } from "inputAdapters/MUITextInputAdapter";
import { DividedButton } from "../DividedButton";

export interface ICreateAccountFormProps {
    onSubmit: (name: string, email: string) => void;
    initialName: string;
    initialEmail: string;
}

export const CreateAccountForm = (props: ICreateAccountFormProps) => {

    const onSubmit = async (values: any) => {

        let name = values.name as string;
        let email = values.email as string;

        if (name) {
            name = name.trim();
        }
        if (email) {
            email = email.trim();
        }

        await props.onSubmit(name, email);
    };

    return (
        <Form onSubmit={onSubmit} initialValues={{ name: `${props.initialName}`, email: `${props.initialEmail}` }} >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className={styles.createAccountForm}>
                    <div id="createAccount__nameContainer" className={styles.fieldContainer}>
                        <Field
                            name="name"
                            autoComplete="name"
                            component={MUITextInputAdapter}
                            variant="outlined"
                            size="small"
                            label="Name"
                            validate={Validation.composeValidators(
                                Validation.optionalStringContains("First and last name are required")(" "),
                                Validation.optionalStringPartMaxLength("First and Last Name must be less than 50 characters")(50)
                            )}
                            placeholder="Name"
                            className={styles.textField}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                            }}
                        />
                    </div>
                    <div id="createAccount__emailContainer" className={styles.fieldContainer}>
                        <Field
                            name="email"
                            autoComplete="email"
                            component={MUITextInputAdapter}
                            variant="outlined"
                            size="small"
                            label="Email"
                            validate={Validation.composeValidators(
                                Validation.optionalIsEmailAddress("Must be a valid email address"),
                                Validation.optionalMaxLength("Email address must be less than 50 characters")(50)
                            )}
                            placeholder="Email"
                            className={styles.textField}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                            }}
                        />
                    </div>

                    <div id="create_account_button_container" className={styles.dividedButtonContainer}>
                        <DividedButton idBase="CreateAccountForm" isSubmit={true} onClick={handleSubmit}>continue</DividedButton>
                    </div>
                </form>
            )}
        </Form>
    );
};