import magnifier from "assets/magnify-illustration.png";
import xIcon from "assets/xIcon_dark.png";
import * as React from "react";
import styles from "./ExistingAccountDetected_DSOne.module.scss";

interface IExistingAccountDetectedDsoneProps {
    onClose: () => void;
    onManageAccount: () => void;
}

export const ExistingAccountDetected_DSOne: React.FC<IExistingAccountDetectedDsoneProps> = (props: IExistingAccountDetectedDsoneProps) => {
    return <div id="existingAccount" className={styles.existingAccount}>
        <button id="existingDsoneAccountCloseButton" className={styles.closeButton} onClick={props.onClose}>
            <img id="existingDsoneAccountCloseImage" className={styles.closeIcon} src={xIcon} alt="close" />
        </button>
        <div className={styles.existingAccount__contentContainer}>

            <img src={magnifier} alt="" />

            <h2 id="existingAccount__headerText" className={styles.existingAccount__headerText}>
                Existing Account Detected
            </h2>
            <p id="existingAccount__subHeaderText" className={styles.existingAccount__subHeaderText}>
                Based on your email we've detected that you already have an account with Driver Support One
            </p>

            <h3 id="existingAccount_loginInstructions" className={styles.loginText}>
                Login with Driver Support One instructions
            </h3>

            <button id="existingAccount__manageButton" className={styles.existingAccount__manageButton} onClick={props.onManageAccount}>manage existing account</button>

        </div>
    </div>
}
