
// these are backend api endpoints
export enum Endpoints {
    Login = "Login",
    CheckExistingAccount = "CheckExistingAccount",
    GetViewModel = "GetViewModel",
    CreditCardPurchase = "CreditCardPurchase",
    PayPalPurchase = "PayPalPurchase",
    UpdateStartWithWindows = "UpdateStartWithWindows",
}

export enum UiEndpoints {
    Welcome = "Welcome",
    Preregistration = "Preregistration",
    Cart = "Cart",
    ThankYou = "ThankYou",
}

declare global {
    interface Window {
        EndpointConfig: Config;
    }
}

interface Config {
    apiUrl: string,
    isDevelopment: boolean,
}

const mapEndpoint = (requestedEndpoint: Endpoints) => {
    let queryString = getCommonQueryString();
    let baseUrl = "";
    if (window.EndpointConfig) {
        baseUrl = window.EndpointConfig.apiUrl;
    }

    switch (requestedEndpoint) {
        case Endpoints.Login:
            return baseUrl + "/Signup/LogInV2" + queryString;
        case Endpoints.GetViewModel:
            return baseUrl + "/Cart/GetCartViewModel" + queryString;
        case Endpoints.CheckExistingAccount:
            return baseUrl + "/Cart/CheckExistingAccountV2" + queryString;
        case Endpoints.CreditCardPurchase:
            return baseUrl + "/Cart/SubmitPaymentInfoV2" + queryString;
        case Endpoints.PayPalPurchase:
            return baseUrl + "/Cart/ExpressCheckoutUrlV2" + queryString;
        case Endpoints.UpdateStartWithWindows:
            return baseUrl + "/Cart/UpdateStartWithWindows" + queryString;
    }
}

export const getCommonQueryString = () => {
    // todo
    // probably should use a qs builder -- check frontend.common
    let queryString = window.location.search;
    // add any common query string stuff here
    
    return queryString;
}

export const mapRoute = (requestedEndpoint: UiEndpoints) => {
    let queryString = window.location.search;
    // add any common query string stuff here

    switch (requestedEndpoint) {
        case UiEndpoints.Cart:
            return { pathname: "/Cart", search: queryString };
        case UiEndpoints.ThankYou:
            return { pathname: "/ThankYou", search: queryString };
        case UiEndpoints.Preregistration:
        default:
            return { pathname: "/Preregistration", search: queryString };
    }
}

export default mapEndpoint;