import * as React from "react";
import { TextField } from "@material-ui/core";

// Adapter for using Material UI component with React Final Form.
export const MUITextInputAdapter = ({ input, meta, ...rest }: { input: any, meta: any }) => (
    <TextField
        {...input}
        {...rest}
        onChange={(event) => input.onChange(event.target.value)}
        helperText={meta.error && meta.touched ? meta.error : " "}
        error={meta.touched && meta.error ? true : false}
    />
)