import { Checkbox, FormControlLabel } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import * as React from "react";
import styles from "./DsOneCheckbox.module.scss";

export interface IDsOneCheckboxProps {
    initial: boolean;
    labelText: string;
    labelClass: string | undefined;
    controlClass: string | undefined;
    checkBoxClass: string | undefined;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const DsOneCheckbox = (props: Partial<IDsOneCheckboxProps>) => {

    const [checked, setChecked] = React.useState(props.initial);
    const labelStyles = (props.labelClass) ? `${props.labelClass} ${styles.label}` : styles.label;
    const controlStyles = (props.controlClass) ? `${props.controlClass} ${styles.formRoot}` : styles.formRoot;
    const checkBoxStyles = (props.checkBoxClass) ? `${props.checkBoxClass} ${styles.root}` : styles.root;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (props.handleChange) {
            props.handleChange(event, event.target.checked);
        }
    };

    const labelNode = <p id="checkbox_label_text">{props.labelText}</p>;

    return <StylesProvider injectFirst>
        <FormControlLabel id='omg'
            control={
                <Checkbox id="dsoneCheckBox"
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{ root: checkBoxStyles, checked: styles.checked }}
                />
            }
            label={labelNode}
            labelPlacement="end"
            classes={{ label: labelStyles, root: controlStyles }} />
    </StylesProvider>
}