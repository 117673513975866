import * as React from "react";
import initialState, { IState } from "./initialState";
import reducer from "./reducer";

type AppProviderProps = { children: React.ReactNode }
type Dispatch = (action: any) => void;
const AppStateContext = React.createContext<IState | undefined>(undefined);
const AppDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const AppProvider = ({ children }: AppProviderProps) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

const useAppState = () => {
    const context = React.useContext(AppStateContext);
    if (context === undefined) {
        throw new Error('useAppState must be used within an AppProvider');
    }
    return context;
};

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext);
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within an AppProvider');
    }
    return context;
};

export { AppProvider, useAppDispatch, useAppState }