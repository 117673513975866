import * as Sentry from '@sentry/react';
import { SendRequestExternalBrowser } from "api/clientFormApi";
import { OptimizelyApi } from "api/optimizelyApi";
import iconDefense from "assets/preregistration_screen/Icon-defense.png";
import iconFolder from "assets/preregistration_screen/Icon-folder.png";
import iconGear from "assets/preregistration_screen/Icon-gear.png";
import iconMonitor from "assets/preregistration_screen/icon-monitor.png";
import mapEndpoint, { Endpoints, mapRoute, UiEndpoints } from "endpointFactory";
import { ICheckExistingAccountData, Products } from "models/ICheckExistingAccountData";
import { IDataGetResponse } from "models/Responses";
import * as React from "react";
import { useFetch } from "react-async";
import ReactGA from 'react-ga';
import { useHistory } from "react-router-dom";
import { App, Msg } from "store/actions";
import { useAppDispatch, useAppState } from "store/context";
import { ExpiredPasswordError } from "utils/Errors";
import { DsOneCheckbox } from "./DsOneCheckbox";
import { DsOneDialog } from "./DsOneDialog";
import { CreateAccountForm } from './forms/CreateAccountForm';

import { ExistingAccountDetected_DSOne as ExistingAccountDs1 } from "./modals/ExistingAccountDetected_DSOne";
import { ExistingAccountDetected_SolveIQ } from './modals/ExistingAccountDetected_SolveIQ';
import { ExistingAccountDetected_V10 as ExistingAccountV10 } from "./modals/ExistingAccountDetected_V10";
import { LoadingModal } from "./modals/LoadingModal";
import styles from "./PreRegistration.module.scss";

export interface IPreRegistrationProps { }

export const PreRegistration: React.FC<IPreRegistrationProps> = (props: IPreRegistrationProps) => {

    const state = useAppState();
    const dispatch = useAppDispatch();
    const history = useHistory();

    // we do nothing with this value for now
    const [keepUserInformed, setKeepUserInformed] = React.useState(false);
    // we don't actually show any error yet, but its wired up if we want to
    const [checkLicenseError, setCheckLicenseError] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [isExistingDsoneAccount, setIsExistingDsoneAccount] = React.useState(false);
    const [isExistingV10Account, setIsExistingV10Account] = React.useState(false);
    const [isExistingSolveIQAccount, setIsExistingSolveIQAccount] = React.useState(false);
    const [portalUrl, setPortalUrl] = React.useState("");

    // avoid stale closure issue in the onResolve
    const emailRef = React.useRef({ email, name });
    emailRef.current = { email, name };

    const url = mapEndpoint(Endpoints.CheckExistingAccount);
    const { isPending, error, run } = useFetch<IDataGetResponse>(
        url!,
        { method: "POST", headers: { "Content-Type": "application/json", "Accept": "application/json" } },
        {
            onResolve: (data: IDataGetResponse) => {
                if (data == null) {
                    Sentry.captureMessage("CheckExistingAccount returned null.")

                    setCheckLicenseError("An error occurred.")
                } else {
                    if (data.success) {
                        // parse the payload
                        const checkData = JSON.parse(data.payload) as ICheckExistingAccountData;

                        if (checkData == null) {
                            // todo: handle parse error
                            // log in sentry as exception?
                            setCheckLicenseError("An error occurred.")
                        }

                        if (checkData.IsExistingAccount && checkData.CurrentCustomer.toLowerCase() === Products.DsOne.toString().toLowerCase()) {
                            ReactGA.event({ category: "Account", action: "existing dsone account detected" });
                            setIsExistingDsoneAccount(true);
                            setPortalUrl(checkData.UserPortalUrl);
                        } else if (checkData.IsExistingAccount && checkData.CurrentCustomer.toLowerCase() === Products.DriverSupport.toString().toLowerCase()) {
                            ReactGA.event({ category: "Account", action: "existing v10 account detected" });
                            setIsExistingV10Account(true);
                            setPortalUrl(checkData.UserPortalUrl);
                        } else if (checkData.IsExistingAccount && checkData.CurrentCustomer.toLowerCase() === Products.SolveIQ.toString().toLowerCase()) {
                            ReactGA.event({ category: "Account", action: "existing solveiq account detected" });
                            setIsExistingSolveIQAccount(true);
                            setPortalUrl(checkData.UserPortalUrl);
                        } else {
                            // account didn't exist, so one was made using the cached email.
                            dispatch(Msg(App.PreRegComplete, { navFunc: history.push(mapRoute(UiEndpoints.Cart)), email: emailRef.current.email, name: emailRef.current.name }));
                        }
                    } else {
                        setCheckLicenseError(data.message);
                    }
                }
            }
        }
    );

    const handleSubmit = (name: string, email: string) => {

        // skip existing account lookup if we don't have an email
        if (email == null || email === "") {

            dispatch(Msg(App.PreRegComplete, { navFunc: history.push(mapRoute(UiEndpoints.Cart)), email, name }))
            return;
        }

        // cache the email for further use
        setEmail(email);
        setName(name);

        OptimizelyApi.LogEvent("SUBMIT_CREATE_ACCOUNT_INFO");
        // check if the email is associated with an account already
        run({ body: JSON.stringify(email) });
    }

    const vmImage = state.viewModel.machineImageBase64;
    const machineImage = vmImage == null ?
        iconMonitor :
        "data:image/png;base64," + vmImage;

    return (
        <div id="prereg_container" className={styles.container}>

            <DsOneDialog disableBackdropClick={true} open={isPending}>
                <LoadingModal loadingMessage={<p id="loadingMessage" className={styles.loadingMessageText}>Creating your account...</p>} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isExistingDsoneAccount}>
                <ExistingAccountDs1
                    onClose={() => {
                        setIsExistingDsoneAccount(false);
                        setPortalUrl("");
                    }}
                    onManageAccount={() => {
                        window.open(portalUrl, "_blank", "noopener,noreferrer");
                    }} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isExistingV10Account}>
                <ExistingAccountV10
                    onClose={() => {
                        setIsExistingV10Account(false);
                        setPortalUrl("");
                    }}
                    onManageAccount={() => {
                        window.open(portalUrl, "_blank", "noopener,noreferrer");
                    }} />
            </DsOneDialog>
            <DsOneDialog disableBackdropClick={true} open={isExistingSolveIQAccount}>
                <ExistingAccountDetected_SolveIQ
                    onClose={() => {
                        setIsExistingSolveIQAccount(false);
                        setPortalUrl("");
                    }}
                    onManageAccount={() => {
                        window.open(portalUrl, "_blank", "noopener,noreferrer");
                    }}
                    onPurchaseLicenses={() => {
                        dispatch(Msg(App.PreRegComplete, { navFunc: history.push(mapRoute(UiEndpoints.Cart)), email: emailRef.current.email, name: emailRef.current.name }));
                    }} />
            </DsOneDialog>


            {/* <img id="prereg_backgroundImage_left" src={containerBackground_left} alt="" className={styles.containerBackground_left} />
            <img id="prereg_backgroundImage_right" src={containerBackground_right} alt="" className={styles.containerBackground_right} /> */}

            <header id="prereg_header" className={styles.header}>
                <h1 className={styles.title}>Get started fixing and optimizing your PC today!</h1>
                {/* <StylesProvider injectFirst>
                    <Button id="cart_back_button" className={styles.backButton}
                        onClick={() => dispatch(Msg(App.PreRegBack, { navFunc: history.push(mapRoute(UiEndpoints.Welcome)) }))}>BACK</Button>
                </StylesProvider> */}
            </header>

            <main id="prereg_body" className={styles.body}>

                <div id="prereg_gutter_left" className={styles.gutter} />

                <div id="prereg_cardLeft" className={styles.card_left}>
                    <div id="prereg__left_header" className={styles.card_header}>
                        {/* <img id="prereg_pcIcon" src={pcIcon} alt="PC" /> */}
                        <h3 id="cardLeft_title" className={styles.card_title}>Your System</h3>
                    </div>
                    <div id="prereg_mainContent" className={styles.main_content}>
                        <div id="prereg_info" className={styles.info}>
                            <h2 id="prereg_pcDetails" className={styles.info_title}>Your PC Details</h2>
                            <p id="prereg_machineName" className={styles.pc}>{`PC Model: ${state.viewModel.machineName}`}</p>
                            <p id="pregreg_machineOS" className={styles.os}>Operating System:{" "}
                                <span id="prereg_osValue" className={styles.os_value}>{state.viewModel.machineOS}</span>
                            </p>
                        </div>
                        <img id="prereg_laptop" src={machineImage} alt="Laptop" className={styles.machineImage} />
                    </div>
                    <div className={styles.horizonalDivider}></div>
                    <div id="prereg_services" className={styles.services}>
                        <div id="prereg_services_title" className={styles.panel_title}>Additional Services Provided</div>
                        <div id="prereg_panel" className={styles.panel}>
                            <div id="driver_service" className={styles.service}>
                                <h4 id="driver_title" className={styles.service_title}>Driver Update Service</h4>
                                <img id="driver_icon" src={iconGear} alt="Gear" />
                            </div>
                            <div className={styles.verticalDivider}></div>
                            <div id="perf_service" className={styles.service}>
                                <h4 id="perf_title" className={styles.service_title}>PC Performance Service</h4>
                                <img id="perf_icon" src={iconFolder} alt="Charts" />
                            </div>
                            <div className={styles.verticalDivider}></div>
                            <div id="support_service" className={styles.service}>
                                <h4 id="support_title" className={styles.service_title}>Signature Support Service</h4>
                                <img id="support_icon" src={iconDefense} alt="Envelope-Defense" />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="prereg_cardRight" className={styles.card_right}>
                    <div id="prereg_right_header" className={styles.card_header}>
                        {/* <img id="prereg_rocket" src={rocket} alt="Rocket" /> */}
                        <h3 className={styles.card_title}>Get Started</h3>
                    </div>

                    <div id="prereg_formContainer" className={styles.form_container}>
                        <p id="create_account__description" className={styles.description_text}>
                            Creating your account is simple. Let's get started setting up your name and email.
                            </p>
                        <CreateAccountForm
                            initialEmail={state.currentEmail != null ? state.currentEmail : state.viewModel.email}
                            initialName={state.currentName != null ? state.currentName : state.viewModel.name}
                            onSubmit={(name, email) => handleSubmit(name, email)} />
                        <DsOneCheckbox initial={true}
                            handleChange={(e, checked: boolean) => setKeepUserInformed(checked)}
                            labelText="Yes! Keep me informed by email regarding product changes and special PC optimization offers" />
                    </div>
                </div>
                <div id="prereg_gutter_right" className={styles.gutter} />
            </main>

            <footer id="prereg_footer" className={styles.footer}>
                <p id="prereg_footer_text_1">Solve iQ is an independent service provider for software products. By downloading you accept the
                        <span>
                        <button id="prereg_privacy_link" className={styles.privacypolicyButton}
                            onClick={() => {
                                ReactGA.event({ category: "Account", action: "redirecting to privacy policy page" });
                                window.open(state.viewModel.privacyPolicyUrl, "_blank", "noopener,noreferrer");
                            }}>
                            Privacy Policy
                            </button>
                    </span>
                        and
                        <span>
                        <button id="prereg_terms_link" className={styles.termsButton}
                            onClick={() => {
                                ReactGA.event({ category: "Account", action: "redirecting to terms and conditions page" });
                                window.open(state.viewModel.termsAndConditionsUrl, "_blank", "noopener,noreferrer");
                            }}>
                            Terms and Conditions
                            </button>
                    </span>
                         . Full functionality requires $9.99 monthly subscription. Use of names and trademarks are for reference only and no affiliation is implied with any named third-party companies.
                     </p>
            </footer>
        </div >
    )
};