import * as Sentry from '@sentry/react';
import * as React from "react";
import { useFetch } from "react-async";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import mapEndpoint, { Endpoints } from 'endpointFactory';
import { ICartViewModel } from "../models/ICartViewModel";
import { App, Msg } from "store/actions";
import { useAppDispatch, useAppState } from "store/context";
import { WrappedError } from "utils/Errors";
import { Cart } from './Cart';
import { PreRegistration } from "./PreRegistration";
import styles from "./Root.module.scss";
import { TitleBar } from "./TitleBar";
import { Loading } from "./Loading";
import { Error } from "./Error";

declare var basename: string;

interface IRootProps { }

const AppLoader: React.FC = () => {
    const dispatch = useAppDispatch();
    const state = useAppState();

    const headers = { Accept: "application/json" }
    const url = mapEndpoint(Endpoints.GetViewModel);
    const { data, error } = useFetch(url!, { headers }, {
        onResolve: (response: ICartViewModel) => {
            dispatch(Msg(App.DataFetchComplete, {
                navFunc: null,
                vm: response
            }));
        },
        onReject: (error: Error) => {
            Sentry.captureException(new WrappedError("Failed to load data model", error));
        },
    });

    // note: this is drawn offscreen while loading so we don't really care
    // what's happening while the data model is being fetched
    if (error) {
        return <Error title="Oops!" message="Something went wrong. We're working on it." />
    }

    if (data) {
        return <AppHost />
    } else {
        return <Loading title="Please wait..." loadingMessage="loading" />
    }
};

export const Root: React.FC<IRootProps> = () => {
    return <div id="rootContainer" className={styles.root}>
        <TitleBar />
        <AppLoader />
    </div>
};

const AppHost: React.FC = () => {
    return (
        <BrowserRouter basename={basename}>
            <Switch>
                <Route path="/cart" component={Cart} />
                <Route component={PreRegistration} />
            </Switch>
        </BrowserRouter>
    );
}