import { IBoundConnectionProperties } from "./interfaces/IBoundConnectionProperties";

declare var CefSharp: any;
declare var clientMouseEvents: IClientMouseEventsBoundObject;
declare var clientWindowCommands: IClientWindowEventsBoundObject;
declare var clientConnectionProperties: IClientConnectionPropertiesBoundObject;

interface IClientMouseEventsBoundObject {
    mouseDownTitleBar(): void;
    mouseUpTitleBar(): void;
    closeClicked(): void;
    minimizeClicked(): void;
    mouseDownBottomRightResize(): void;
    mouseDownRightResize(): void;
    mouseDownBottomResize(): void;
    mouseDownTopRightResize(): void;
    mouseDownBottomLeftResize(): void;
    mouseDownLeftResize(): void;
    mouseDownTopLeftResize(): void;
    mouseDownTop(): void;
}

interface IClientWindowEventsBoundObject {
    onClientReady(): void;
    onCloseWindow(): void;
    windowNeedsFocus(): void;
    requestExternalBrowser(url: string): Promise<void>;
}

interface IClientConnectionPropertiesBoundObject {
    getConnectionProperties(): Promise<IBoundConnectionProperties>;
}

export const SendTitleBarMouseDown = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownTitleBar();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendTitleBarMouseDown);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeBottomRight = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownBottomRightResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeBottomRight);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeBottomLeft = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownBottomLeftResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeBottomLeft);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeRight = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownRightResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeRight);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}


export const SendResizeBottom = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownBottomResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeBottom);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeTop = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownTop();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeTop);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeTopLeft = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownTopLeftResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeTopLeft);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeLeft = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownLeftResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeLeft);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendResizeTopRight = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseDownTopRightResize();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendResizeTopRight);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendTitleBarMouseUp = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.mouseUpTitleBar();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendTitleBarMouseUp);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendCloseClicked = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.closeClicked();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendCloseClicked);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendMinimizeClicked = () => {
    if (typeof (clientMouseEvents) !== "undefined") {
        clientMouseEvents.minimizeClicked();
    } else {
        try {
            CefSharp.BindObjectAsync("clientMouseEvents").then(SendMinimizeClicked);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendOnClientReady = () => {
    if (typeof (clientWindowCommands) !== "undefined") {
        clientWindowCommands.onClientReady();
    } else {
        try {
            CefSharp.BindObjectAsync("clientWindowCommands").then(SendOnClientReady);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendWindowNeedsFocus = () => {
    if (typeof (clientWindowCommands) !== "undefined") {
        clientWindowCommands.windowNeedsFocus();
    } else {
        try {
            CefSharp.BindObjectAsync("clientWindowCommands").then(SendWindowNeedsFocus);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const SendRequestExternalBrowser = async (url: string): Promise<void> => {
    if (typeof (clientWindowCommands) !== "undefined") {
        await clientWindowCommands.requestExternalBrowser(url);
    } else {
        try {
            await CefSharp.BindObjectAsync("clientWindowCommands").then(SendRequestExternalBrowser);
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return;
            }
            throw e;
        }
    }
}

export const GetConnectionProperties = async (): Promise<IBoundConnectionProperties | null> => {
    if (typeof (clientConnectionProperties) !== "undefined") {
        const props = await clientConnectionProperties.getConnectionProperties();

        if (props == null) {
            throw new Error("Unable to receive connection props");
        }
        let key = null;
        if (props.EncryptionKey != null) {
            key = {
                Key: Uint8Array.from(props.EncryptionKey.Key),
                IV: Uint8Array.from(props.EncryptionKey.IV)
            };
        }
        return {
            EncryptionKey: key,
            ClearPort: props.ClearPort,
            EncryptionPort: props.EncryptionPort,
        }
    } else {
        try {
            await CefSharp.BindObjectAsync("clientConnectionProperties");
            return GetConnectionProperties();
        } catch (e) {
            if (e instanceof ReferenceError) {
                // this will occur if we're rendering in a browser directly
                return null;
            }
            throw e;
        }
    }
}
