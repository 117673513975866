export class WrappedError extends Error {

    public innerException: Error;

    constructor(message: string, innerExcpetion: Error) {
        super(message);
        this.innerException = innerExcpetion;
    }
}

export class BadUserNamePasswordError extends Error {
    constructor(message?: string) {
        // restore prototype chain   
        const actualProto = new.target.prototype;
        // 'Error' breaks prototype chain here
        super(message ? message : "Email is not associated with an account or password is incorrect.");
        Object.setPrototypeOf(this, actualProto);
    }
}

export class ExpiredPasswordError extends Error {
    public expiredEmail: string;
    constructor(email: string, message?: string) {
        // restore prototype chain   
        const actualProto = new.target.prototype;
        // 'Error' breaks prototype chain here
        super(message ? message : "Password is expired.");
        Object.setPrototypeOf(this, actualProto);
        this.expiredEmail = email;
    }
}

export class UnknownResponseError extends Error {
    constructor(message?: string) {
        // restore prototype chain   
        const actualProto = new.target.prototype;
        // 'Error' breaks prototype chain here
        super(message ? message : "Unknown response from server.");
        Object.setPrototypeOf(this, actualProto);
    }
}

export class ServerUnavailable extends Error {
    constructor(message?: string) {
        // restore prototype chain   
        const actualProto = new.target.prototype;
        // 'Error' breaks prototype chain here
        super(message ? message : "The login server is unavailable.");
        Object.setPrototypeOf(this, actualProto);
    }
}