import logo from "assets/preregistration_screen/solveiq_logo.png";
import * as React from "react";
import { App, Msg } from "../store/actions";
import { useAppDispatch } from "../store/context";
import styles from "./TitleBar.module.scss";
import { TitleBarCloseButton } from "./TitleBarCloseButton";
import { TitleBarMinimizeButton } from "./TitleBarMinimizeButton";

export const TitleBar: React.FC = () => {
    const dispatch = useAppDispatch();

    const mouseDownThunk = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(Msg(App.StartDrag));
    }

    const minimizeThunk = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(Msg(App.Minimize));
    }

    const closeThunk = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(Msg(App.Close));
    }

    return (
        <div id="titleBar_container" className={styles.titleBar} onMouseDown={mouseDownThunk}>
            <div id="titleBar_company" className={styles.company}>
                <img id="titleBar_logo" className={styles.icon} src={logo} alt="logo" />
                {/* <span id="titleBar_driver" className={styles.title__emphasized}>Driver</span>
                <span id="titleBar_support" className={styles.title}>Support</span>
                <span id="titleBar_separator" className={styles.title__separator}> | </span>
                <span id="titleBar_one" className={styles.title}>One</span> */}
            </div>

            {/*<div id="titleBar_actions" className={styles.actions}>
                <TitleBarMinimizeButton onMinimize={minimizeThunk} />
                <TitleBarCloseButton onClose={closeThunk} />
            </div>*/}
        </div>
    );
};

