import moment, { Moment } from "moment";

export class Validation {

    // https://medium.com/javascript-scene/curry-and-function-composition-2c208d774983
    // curried functions
    public static composeValidators = (...validators: any[]) => (value: any) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

    //regex copied from https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
    //public static emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    // simpler regex that allows capital letters in the email
    public static emailRegex = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/;

    public static required = (errorText: string) => (value: string) => (value ? undefined : errorText);
    public static mustBeNumber = (value: number) => (isNaN(value) ? "Must be a number" : undefined);
    public static minValue = (errorText: string) => (min: number) => (value: number) => isNaN(value) || value >= min ? undefined : errorText;
    public static maxLength = (errorText: string) => (max: number) => (value: { trim: () => { (): string; new(): string; length: number; }; }) => value.trim().length <= max ? undefined : errorText;
    public static minLength = (errorText: string) => (min: number) => (value: { trim: () => { (): string; new(): string; length: number; }; }) => value.trim().length >= min ? undefined : errorText;
    public static luhns = (customErrorText: string) => (value: string) => Validation.isValidLuhns(value) ? undefined : customErrorText;
    public static expDateFormat = (customErrorText: string) => (value: string) => Validation.isValidDate(value) ? undefined : customErrorText;
    public static expirationDate = (customerErrorText: string) => (currentDate: any) => (value: string) => Validation.isValidExpirationDate(value, currentDate) ? undefined : customerErrorText;
    public static stringContains = (errorText: string) => (searchString: string) => (value: string) => value.trim().indexOf(searchString) > -1 ? undefined : errorText;
    public static isEmailAddress = (errorText: string) => (value: string) => Validation.emailRegex.test(value.trim()) ? undefined : errorText;
    public static stringPartMaxLength = (errorText: string) => (max: number) => (value: string) => Validation.isEachStringLessThanMax(value.trim(), max) ? undefined : errorText;

    // only apply these "optional" validations if there's a value
    // this is a very awkward and non-scalable way to implement the 
    // "value is not required, but if it's provided do these validations" usecase
    // todo: make this usecase easier to deal with
    public static optionalMaxLength = (errorText: string) => (max: number) => (value: { trim: () => { (): string; new(): string; length: number; }; }) => {
        if (value == null || value.trim().length === 0) { return undefined }
        return Validation.maxLength(errorText)(max)(value);
    }

    public static optionalStringPartMaxLength = (errorText: string) => (max: number) => (value: string) => {
        if (value == null || value === "") { return undefined }
        return Validation.stringPartMaxLength(errorText)(max)(value);
    }

    public static optionalStringContains = (errorText: string) => (searchString: string) => (value: string) => {
        if (value == null || value === "") { return undefined }
        return Validation.stringContains(errorText)(searchString)(value);
    }
    public static optionalIsEmailAddress = (errorText: string) => (value: string) => {
        if (value == null || value === "") { return undefined }
        return Validation.isEmailAddress(errorText)(value);
    }

    //public static expirationDate = customerErrorText => currentDate => value => {

    //    if (Validation.isValidExpirationDate(value, currentDate)) {
    //        return undefined;
    //    } else {
    //        return customerErrorText;
    //    }

    //}

    public static minLengthNoWhitespace = (errorText: string) => (min: number) => (value: string) => {

        // remove all whitespace
        const trimmed = value.replace(/\s/g, "");

        if (trimmed.length >= min) {
            // passes validation
            return undefined
        } else {
            // error
            return errorText;
        }
    }

    private static isEachStringLessThanMax(inputString: string, maxLength: number): boolean {

        if (inputString == null) {
            return false;
        }

        for (let substring of inputString.split(' ')) {
            if (substring.length > maxLength) {
                return false;
            }
        }
        return true;
    }


    private static isValidLuhns(inputNum: string) {

        // remove all non digit characters
        const trimmed = inputNum.replace(/\D/g, "");

        var digit, digits, flag, sum, _i, _len;
        flag = true;
        sum = 0;
        digits = (trimmed + '').split('').reverse();
        for (_i = 0, _len = digits.length; _i < _len; _i++) {
            digit = digits[_i];
            digit = parseInt(digit, 10);
            if ((flag = !flag)) {
                digit *= 2;
            }
            if (digit > 9) {
                digit -= 9;
            }
            sum += digit;
        }
        return sum % 10 === 0;
    }

    private static isValidDate(inputDate: string) {

        // MMyy
        if (inputDate.length < 4) {
            return false;
        }

        const momentDate = moment(inputDate, "MMYY");
        return momentDate.isValid
    }

    private static isValidExpirationDate(expDateString: string, currentDate: Moment) {

        // incoming dates should be in MMyy format
        if (expDateString.length < 4) {
            return false;
        }

        const momentDate = moment(expDateString, "MMYY");
        if (!momentDate.isValid) {
            return false;
        }

        return momentDate.endOf("month") > currentDate;
    }

}