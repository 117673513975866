import * as React from "react";
import { TextField } from "@material-ui/core";
import { NumberFormatInputAdapter } from "./NumberFormatInputAdapter";

// Adapter for using Material UI component with React Final Form.
export const MUINumberInputAdapter = ({ input, meta, inputProps, InputProps, ...rest }: { input: any, meta: any, inputProps: any, InputProps: any }) => (
    <TextField
        {...input}
        {...rest}
        onChange={(event) => input.onChange(event.target.value)}
        helperText={meta.touched && meta.error ? meta.error : " "}
        error={meta.touched && meta.error ? true : false}

        inputProps={{ ...inputProps }}
        InputProps={{
            ...InputProps,
            inputComponent: NumberFormatInputAdapter,

        }}
    />
)


