import { InputAdornment } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Moment } from "moment";
import * as React from "react";
import { Field, Form } from "react-final-form";
import small_calendar from "assets/cart_screen/small_calendar.png";
import small_lock from "assets/cart_screen/small_lock.png";
import small_user from "assets/cart_screen/small_user.png";
import small_email from "assets/preregistration_screen/email_icon.svg";
import cc_back from "assets/cart_screen/credit_card_back.png";
import styles from "./CartForm.module.scss";
import { Validation } from "utils/Validation";
import { MUITextInputAdapter } from "inputAdapters/MUITextInputAdapter";
import { MUICreditCardInputAdapter } from "inputAdapters/MUICreditCardInputAdapter";
import { MUIDateInputAdapter } from "inputAdapters/MUIDateInputAdapter";
import { MUINumberInputAdapter } from "inputAdapters/MUINumberInputAdapter";
import { MUIPhoneNumberInputAdapter } from "inputAdapters/MUIPhoneNumberInputAdapter";
import { DividedButton } from "components/DividedButton";


export interface ICartFormProps {
    onSubmit: (name: string,
        email: string,
        cardNumber: string,
        cardExpirationMonth: string,
        cardExpirationYear: string,
        cardCvc: string,
        phoneNumber: string,
        zipCode: string) => void;
    initialName: string | null;
    initialEmail: string | null;
    currentDate: Moment;
}

export const CartForm = (props: ICartFormProps) => {

    const onSubmit = async (values: any) => {
        const email = (values.email as string).trim();
        const name = (values.nameOnCard as string).trim();
        const ccNumber = (values.cardNumber as string).trim().replace(/\s/g, "");
        const ccExpirationMonth = (values.cardExpiration as string).trim().substring(0, 2);
        const ccExpirationYear = (values.cardExpiration as string).trim().substring(2, 4);
        const ccCvc = (values.cardCVC as string).trim();
        const phoneNumber = values.cardPhone == null ? "" : (values.cardPhone as string).trim();
        const zipCode = values.zipCode == null ? "" : (values.zipCode as string).trim();

        await props.onSubmit(name, email, ccNumber, ccExpirationMonth, ccExpirationYear, ccCvc, phoneNumber, zipCode);
    };

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {


            },
            textField: {
                '& .MuiFormLabel-root': {
                    '&.Mui-focused': {
                        color: "#4393CF",
                    },
                },
                '& .MuiOutlinedInput-root': {
                    background: "white",
                    '&.Mui-focused fieldset': {
                        borderColor: "#4393CF",
                    },
                },
            },
            cardNumber: {
                '&::placeholder': {
                    fontSize: 30,
                    position: "relative",
                    top: 5,
                }
            },
            cardExpiration: {
            },
            cardCVC: {
                '&::placeholder': {
                    fontSize: 30,
                    position: "relative",
                    top: 5,
                }
            },
        },
        ));

    // ************** DO NOT USE withStyles hook ***************
    // withStyles will cause the input to lose focus when first character
    // is typed in. no idea why. just use makeStyles instead.

    //const CartTextField = withStyles({
    //    root: {

    //        '& .MuiOutlinedInput-root': {

    //            background: "white",

    //            '& fieldset': {
    //                borderColor: 'red',
    //            },
    //            '&:hover fieldset': {
    //                borderColor: 'yellow',
    //            },
    //            '&.Mui-focused fieldset': {
    //                borderColor: '#4393CF',
    //            },
    //        },
    //    },
    //})(TextField);

    const classes = useStyles({});

    return (
        <Form onSubmit={onSubmit} initialValues={{ email: `${props.initialEmail}`, nameOnCard: `${props.initialName}` }}>
            {({ handleSubmit, submitting }) => (
                <form id="cartForm" onSubmit={handleSubmit} className={styles.cartCompactForm}>
                    <div id="cartCompact_leftColumn" className={styles.form_column_left}>
                        <div id="cartCompact_emailContainer" className={styles.cartCompact_fieldContainer}>
                            <Field
                                name="email"
                                autoComplete="email"
                                component={MUITextInputAdapter}
                                variant="outlined"
                                size="small"
                                label="Email"
                                validate={Validation.composeValidators(
                                    Validation.required("Email is required"),
                                    Validation.isEmailAddress("Must be a valid email address"),
                                    Validation.maxLength("Email address must be less than 50 characters")(50)
                                )}
                                placeholder="Email"
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}


                            />
                        </div>
                        <div id="cartCompact_nameContainer" className={styles.cartCompact_fieldContainer}>
                            <Field
                                name="nameOnCard"
                                autoComplete="cc-name"
                                component={MUITextInputAdapter}
                                variant="outlined"
                                size="small"
                                validate={Validation.composeValidators(
                                    Validation.required("Name is required"),
                                    Validation.stringContains("First and last name are required")(" "),
                                    Validation.stringPartMaxLength("First and Last Name must be less than 50 characters")(50)
                                )}
                                label="Name On Card"
                                placeholder="Name on Card"
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}

                            />
                        </div>
                        <div id="cardCompact_ccInfoContainer" className={styles.cardCompact_ccInfoContainer}>
                            <div id="cartCompact_cardExpirationContainer" className={styles.expirationContainer}>
                                <Field
                                    name="cardExpiration"
                                    autoComplete="cc-exp"
                                    component={MUIDateInputAdapter}
                                    variant="outlined"
                                    size="small"
                                    validate={Validation.composeValidators(
                                        Validation.required("Expiration required"),
                                        Validation.expDateFormat("Invalid expiration"),
                                        Validation.expirationDate("Invalid expiration")(props.currentDate))}
                                    label="Card Expiration"
                                    placeholder="MM/YY"
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </div>

                            <div id="cartCompact_cvcContainer" className={styles.cvcContainer}>
                                <Field
                                    name="cardCVC"
                                    autoComplete="cc-csc"
                                    component={MUINumberInputAdapter}
                                    variant="outlined"
                                    size="small"
                                    validate={Validation.composeValidators(
                                        Validation.required("CVC is required"),
                                        Validation.minLength("Invalid CVC")(3))}
                                    label="CVC"
                                    placeholder="•••"
                                    className={classes.textField}
                                    inputProps={{ format: "####" }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        endAdornment: <InputAdornment position="end"><img id="cc_back_image" src={cc_back} /></InputAdornment>,
                                        classes: { input: classes.cardCVC }
                                    }}
                                />
                            </div>

                            <div id="cartCompact_zipCodeContainer" className={styles.zipCodeContainer}>
                                <Field
                                    name="zipCode"
                                    autoComplete="zipcode"
                                    component={MUINumberInputAdapter}
                                    variant="outlined"
                                    size="small"
                                    validate={Validation.composeValidators(
                                        Validation.optionalMaxLength("Must be less than 20 digits")(20)
                                    )}
                                    label="Zip Code"
                                    placeholder="Zip Code"
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div id="cartCompact_rightColumn" className={styles.form_column_right}>

                        <div id="cartCompact_phoneContainer" className={styles.cartCompact_fieldContainer}>
                            <Field
                                name="cardPhone"
                                autoComplete="phone"
                                component={MUIPhoneNumberInputAdapter}
                                variant="outlined"
                                size="small"

                                label="Phone Number"
                                placeholder="Phone Number"
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                            />
                        </div>

                        <div id="cartCompact_cardContainer" className={styles.cartCompact_ccNumberContainer}>
                            <Field
                                name="cardNumber"
                                autoComplete="cc-number"
                                component={MUICreditCardInputAdapter}
                                variant="outlined"
                                size="small"
                                validate={Validation.composeValidators(
                                    Validation.required("Card number is required"),
                                    Validation.minLengthNoWhitespace("Invalid card number")(13),
                                    Validation.luhns("Invalid card number"))}
                                label="Card Number"
                                placeholder="•••• •••• •••• ••••"
                                className={classes.textField}
                                InputProps={{
                                    classes: { input: classes.cardNumber }
                                }}
                            />
                        </div>


                        <div id="cart_button_container" className={styles.button_container}>
                            <DividedButton idBase="CartForm" isSubmit={true} onClick={handleSubmit}
                                disabled={submitting}>COMPLETE PURCHASE</DividedButton>
                        </div>
                    </div>
                </form>
            )}
        </Form>
    );
};


